<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("TAX_GROUPS.ADD_TAX_GROUP") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <tax-group-form
              :loading="loading"
              :taxGroupData="taxGroup"
              :formErrors="formErrors"
              @taxGroupSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultTaxGroup from "./defaultTaxGroup";
import TaxGroupForm from "./partials/TaxGroupForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TaxGroupForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      taxGroup: cloneDeep(defaultTaxGroup),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List TaxGroups" });
    },

    viewTaxGroup(taxGroup) {
      this.alertLeave = false;
      this.$router.push({ name: "View TaxGroup", params: { id: taxGroup.id } });
    },

    async handleSubmit(taxGroup) {
      this.loading = true;

      const taxGroupData = cloneDeep(taxGroup);
      delete taxGroupData.id;
      if (!taxGroupData.organization.id) {
        delete taxGroupData.organization;
      }

      try {
        await this.$store.dispatch("taxGroups/add", taxGroupData);
        this.$notify({
          type: "success",
          message: this.$t("TAX_GROUPS.TAX_GROUP_ADDED"),
        });
        const taxGroup = await this.$store.getters["taxGroups/taxGroup"];
        this.viewTaxGroup(taxGroup);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const taxGroup = await this.$store.getters["taxGroups/taxGroup"];
        if (taxGroup.id) {
          await this.$store.dispatch("taxGroups/destroy", taxGroup.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
